<template>
  <d-form-item
    :label="label"
    :error="error"
    :tooltip="tooltip"
  >
    <el-input
      v-model="model"
      class="font-normal"
      :autofocus="autofocus"
      :disabled="disabled"
      :placeholder="placeholder"
      :show-password="password"
      :required="required"
      :type="type"
      :clearable="clearable"
      :prefix-icon="prefixIcon"
      :size="size"
      :autocomplete="autocomplete"
      :rows="rows"
      :maxlength="maxlength"
      :show-word-limit="showWordLimit"
      :resize="resize"
      :readonly="readonly"
      :min="min"
      :max="max"
      :input-style="inputStyle"
      @focus="emits('focus', $event)"
      @input="emits('input', $event)"
      @keydown="$event.stopPropagation()"
    >
      <template
        v-if="$slots['prepend']"
        #prepend
      >
        <slot name="prepend" />
      </template>
      <template
        v-if="$slots['append']"
        #append
      >
        <slot name="append" />
      </template>
    </el-input>
  </d-form-item>
</template>

<script setup lang="ts">
import DFormItem from '@/Components/ui/DFormItem.vue'
import type { CSSProperties } from 'vue'
const model = defineModel<string | number | undefined | null>({
  default: ''
})
const {
  autocomplete = 'none',
  type = 'text',
  prefixIcon = '',
  size = 'large',
  rows = 2
} = defineProps<{
  label?: string
  tooltip?: string
  placeholder?: string
  error?: string
  disabled?: boolean
  password?: boolean
  required?: boolean
  autofocus?: boolean
  autocomplete?: string
  type?: string
  clearable?: boolean
  prefixIcon?: string
  size?: 'large' | 'default' | 'small'
  rows?: number
  maxlength?: number
  showWordLimit?: boolean
  readonly?: boolean
  resize?: 'none' | 'both' | 'horizontal' | 'vertical'
  min?: number
  max?: number
  inputStyle?: string | CSSProperties | CSSProperties[] | string[]
}>()

const emits = defineEmits(['focus', 'input'])
</script>
